//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "HomeView",
  data() {
    return {
      list: [],
      loading: false,
      totalCounts: 0,
      salesData: {
        target: 0,
        sum_achievement: 0,
        sum_sale_achievement: 0,
        sum_fish: 0,
        mall_month_achievement: 0,
        mall_month_return: 0,
        pos_month_achievement: 0,
        pos_month_return: 0,
        month_fish: 0,
        month_card: 0,
        month_achievement_pre: 0
      },
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      sex: '',
      name: '',
      currentMonth: this.$moment(),
      isshowCal:false
    };
  },
  mounted() {

    window.document.title = "全员营销";
    this.getInitData()
    this.getHrinfo()
    this.currentMonth=this.$moment()

  },
  activated() {

  }
  ,
  methods: {
    changeCal(){
      this.currentMonth=this.currentDate;
      this.isshowCal=false;
      this.getInitData()
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    formatterDate(date) {
      return this.$moment(date).format('yyyy/MM')
    },
    getInitData() {
      console.log(this.$moment().startOf("year").format('yyyy,MM,DD'),this.$moment().endOf("month").format('yyyy,MM,DD'),this.$moment(this.currentMonth).endOf("month").format('yyyy,MM,DD'))
      const that = this;
      this.$toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      });
      var pdata = [{
        'PARAMETER': 'Z0CALDAY_MVSM_003',
        'SIGN': 'I',
        'OPTION': 'EQ',
        'LOW': this.$moment(this.currentDate).endOf("month").format('yyyyMMDD'),
        'HIGH': '',
        "LOW_FLAG": "0"

      }, {
        'PARAMETER': 'Z0EMPLOYEE_CMOO_001',
        'SIGN': 'I',
        'OPTION': 'EQ',
        'LOW': this.$memberInfo.hhrEmpid,
        //  'LOW': '10021711',
        "LOW_FLAG": "1",
        'HIGH': ''
      },{
        'PARAMETER': 'Z0CALDAY_MVIM_001',
        'SIGN': 'I',
        'OPTION': 'BT',
        'LOW': this.$moment(this.currentDate).startOf("year").format('yyyyMMDD'),
        'HIGH':this.$moment().endOf("month").format('yyyyMMDD'),
        "LOW_FLAG": "1"
      }]
      this.axios
        .post(
          "/" + this.$ajaxPrefix.consumer +
          "/bw/BwApi/dd_staffSaleData",
          pdata
        )
        .then((res) => {
          this.$toast.clear();
          console.log("dd_staffSaleData", res.data);
          if (res && res.data && res.data.data && res.data.data.length > 0) {
            this.salesData = res.data.data[0]
          }
        });
    },
    getHrinfo() {
      this.axios
        .get(
          "/" + this.$ajaxPrefix.hr +
          "/v1/hr/selectHrPersonnelByUserId?hrId=" + this.$memberInfo.hhrEmpid
          // "/v1/hr/selectHrPersonnelByUserId?hrId=10021711"

        )
        .then((res) => {
          console.log("selectHrPersonnelByUserId", res.data);
          if (res && res.data && res.data.data) {
            this.sex = res.data.data.gender
            this.name = res.data.data.name
          }
        });
    },
    formatMoney(num) {
      var fomartNum = Math.floor(num)
      var reg = /\d{1,3}(?=(\d{3})+$)/g;
      return (fomartNum + '').replace(reg, '$&,');
    },
    toHistory() {
      this.$router.push('/staffsale/history')
    }
  }
}

